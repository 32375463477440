.claim-modal {
  &-claim-btn {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.54);
    color: #ffffff;
    border-radius: 20px;
    padding: 8px 10px;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    &-icon {
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-right: 5px;
      text-align: center;
      line-height: 24px;
      img {
        display: inline-block;
        width: 14px;
        height: 18px;
      }
    }
    span {
      vertical-align: middle;
    }
  }
  &-content {
    position: absolute;
    top: 100px;
    left: 50%;
    width: 700px;
    margin-left: -350px;
    font-size: 14px;
    &.modal-dialog {
      .modal-header {
        height: 50px;
        width: 100%;
        position: relative;
        // background-color: #F2F2F2;
        color: rgba(0, 0, 0, 0.87);
        font-size: 20px;
        padding: 0 20px;
        line-height: 50px;
      }
      .modal-body {
        padding: 20px;
        padding-top: 0;
      }
    }
    ::-webkit-input-placeholder {
      /* WebKit browsers */
      color: rgba(0, 0, 0, 0.32);
    }
    :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: rgba(0, 0, 0, 0.32);
    }
    ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: rgba(0, 0, 0, 0.32);
    }
    :-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: rgba(0, 0, 0, 0.32);
    }
    .claim-text {
      margin: 20px 0;
      &-href {
        color: #f55b23;
        text-decoration: underline;
      }
    }
    .input-box {
      margin-bottom: 30px;
      &-value {
        width: 100%;
        font-size: 14px;
        border: 1px solid #dcdcdc;
        height: 36px;
        line-height: 36px;
        border-radius: 4px;
        padding: 0 14px;
        &:hover {
          border: 1px solid #f55b23;
        }
        &:focus {
          border: 1px solid #f55b23;
        }
      }
      &-error {
        margin-top: 10px;
        // padding-left: 2px;
        line-height: 1.524;
        // color: #f5222d;
        transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
        font-size: 14px;
      }
    }
    .modal-btn-box {
      text-align: right;
      margin: 30px 30px 10px 0;
      .modal-btn {
        cursor: pointer;
        display: inline-block;
        height: 36px;
        background: #f55b23;
        border-radius: 4px;
        color: white;
        line-height: 36px;
        font-size: 14px;
        padding: 0 14px;
        border: 1px solid transparent;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        &:hover {
          background-color: #ffffff;
          border: 1px solid #f55b23;
          color: #f55b23;
        }
        &.cancel {
          background-color: rgb(242, 242, 242);
          color: rgba(0, 0, 0, 0.54);
          margin-right: 20px;
          &:hover {
            border: 1px solid transparent;
            background-color: rgba(153, 153, 153, 0.2);
          }
        }
        &.disabled {
          color: rgba(0, 0, 0, 0.25);
          background-color: #f5f5f5;
          border-color: transparent;
          cursor: not-allowed;
          .btn-loading {
            margin-right: 8px;
          }
        }
        .btn-loading {
          display: inline-block;
          width: 14px;
          height: 14px;
          img {
            width: 100%;
            height: 100%;
            display: inline-block;
            animation: loading 1s linear infinite;
          }
        }
      }
    }
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.claim-modal-terms {
  a {
    color: #1890ff;
  }
  .title-2 {
    opacity: 0.56;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    line-height: 20px;
    margin-bottom: 16px;
  }
  
  .terms-content {
    height: 318px;
    font-size: 14px;
    color: #000000;
    text-align: left;
    border: 1px solid #DCDCDC;
    border-radius: 4px;
    padding: 10px;
    overflow-y: auto;
    &-t {
      margin-bottom: 18px;
      font-weight: 500;
    }
    &-d { 
      margin-top: 0;
      margin-bottom: 0.8em;
    }
  }
}