.font20bb {
  font-size: 20px;
  color: black;
  font-weight: 600;
}
.font14br {
  font-size: 14px;
  color: black;
  font-weight: 400;
}
.font12cr {
  font-size: 12px;
  color: #9f9f9f;
  font-weight: 400;
}
.font16cr {
  font-size: 16px;
  color: #777;
  font-weight: 400;
}
.font30bb {
  font-size: 30px;
  color: black;
  font-weight: 600;
}
.font14cr {
  font-size: 14px;
  color: #777;
  font-weight: 400;
}
.font16br {
  font-size: 16px;
  color: black;
  font-weight: 400;
}
.font16bb {
  font-size: 16px;
  color: black;
  font-weight: 600;
}
.font20cb {
  font-size: 20px;
  color: #F55B23;
  font-weight: 600;
}
.font20cr {
  font-size: 20px;
  color: #777;
  font-weight: 400;
}
#trackList .commentList {
  padding-top: 0;
}
#trackList .ch_feed {
  border-bottom: 1px solid #dcdcdc;
}
#trackList .ch_feed-top {
  padding: 30px 0;
}
#trackList .ch_feed-top .ch_feed-cover {
  padding: 0 20px 12px 0;
  float: left;
  width: 288px;
  height: 280px;
  overflow: hidden;
  position: relative;
}
#trackList .ch_feed-top .ch_feed-cover .img {
  border-radius: 4px;
}
#trackList .ch_feed-top .ch_feed_info {
  text-align: left;
  padding: 10px 0 0;
}
#trackList .ch_feed-top .ch_feed_info .ch_feed_info_title {
  line-height: 42px;
  font-size: 30px;
  color: black;
  font-weight: 600;
}
#trackList .ch_feed-top .ch_feed_info .ch_feed_info_title .box-ellipsis-lineClamp {
  width: auto;
  max-width: 610px;
}
#trackList .ch_feed-top .ch_feed_info .ch_feed_info_title .explicit {
  margin-right: 6px;
  margin-top: -4px;
  width: 26px;
  height: 26px;
}
#trackList .ch_feed-top .ch_feed_info .channelInfo {
  margin-top: 20px;
}
#trackList .ch_feed-top .ch_feed_info .channelInfo .author {
  margin-bottom: 10px;
}
#trackList .ch_feed-top .ch_feed_info .channelInfo .count {
  display: inline-block;
  font-size: 14px;
  height: 19px;
  padding: 0 4px 0 0;
  margin-right: 6px;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: auto 12px;
  line-height: 19px;
}
#trackList .ch_feed-top .ch_feed_info .funcBtn {
  margin-top: 24px;
}
#trackList .ch_feed-top .ch_feed_info .funcBtn .sharePageBtn {
  margin-left: 26px;
}
#trackList .ch_feed-top .ch_feed_info .des-title {
  font-size: 20px;
  color: black;
  font-weight: 600;
  margin-top: 30px;
  line-height: 28px;
  height: 28px;
}
#trackList .ch_feed-top .ch_feed_info .des-con {
  font-size: 14px;
  color: #777;
  font-weight: 400;
  margin-top: 10px;
  line-height: 130%;
}
#trackList .ch_feed-top .ch_feed_info .des-con * {
  font-size: 14px;
}
#trackList .ch_feed-socials {
  margin-top: 20px;
}
#trackList .ch_feed-socials-item {
  width: 32px;
  height: 32px;
  margin-right: 16px;
}
#trackList .ch_feed-socials a {
  display: inline-block;
}
#trackList .ch_feed-copyright {
  margin-top: 10px;
  font-size: 14px;
  line-height: 18px;
  min-height: 18px;
  color: #9b9b9b;
}
#trackList .ch_feed .ch_feed_bottom {
  padding: 0.1rem 0.3rem 0.3rem;
}
#trackList .ch_feed .ch_feed_bottom span {
  text-align: left;
}
#trackList .ch_feed .ch_feed_bottom .author {
  margin-bottom: 0.12rem;
  height: 1.2em;
  line-height: 1.2em;
  overflow: hidden;
}
#trackList .ch_feed .ch_feed_bottom .conClose {
  max-height: 2.4em;
  overflow: hidden;
}
#trackList .ch_feed .ch_feed_bottom .conClose .textTwoClamp {
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
#trackList .ch_feed .ch_feed_bottom .conShow {
  height: auto;
  padding: 0;
}
#trackList .ch_feed .ch_feed_bottom .seeMorebtn {
  text-align: center;
  padding: 0;
  min-width: 32px;
}
#trackList .ch_feed .ch_feed_bottom .seeMorebtn .img {
  cursor: pointer;
  width: 0.4rem;
}
#trackList .ch_feed .ch_feed_bottom .seeMorebtn_up {
  transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  /* Firefox 4 */
  -webkit-transform: rotate(180deg);
  /* Safari and Chrome */
  -o-transform: rotate(180deg);
  /* Opera */
}
#trackList .ch_feed .ch_feed_bottom .notShowBtn {
  height: 0;
  overflow: hidden;
}
#trackList .main {
  height: 100%;
  /*ch_feed*/
  /* trackListCon */
}
#trackList .main .topRow {
  height: 100%;
}
#trackList .main .textH {
  font-size: 1.14em;
  line-height: 120%;
}
#trackList .main .textH2 {
  font-size: 100%;
  line-height: 120%;
}
#trackList .main .textP {
  font-size: 0.86em;
  line-height: 120%;
  color: #666666;
}
#trackList .main .moreTrack {
  text-align: center;
  font-size: 100%;
  line-height: 120%;
  line-height: 250%;
  padding: 0.2rem 0;
  cursor: pointer;
}
#trackList .main .trackListCon {
  padding-top: 20px;
}
#trackList .main .trackListCon .trackListCon_title {
  font-size: 1.14em;
  line-height: 120%;
  float: left;
  padding: 0;
  margin-bottom: 30px;
  font-weight: 400;
  height: 20px;
  line-height: 20px;
}
#trackList .main .trackListCon .funcBtn {
  cursor: pointer;
  padding-right: 20px;
  font-size: 16px;
  color: #777;
  font-weight: 400;
}
#trackList .main .trackListCon .funcBtn:hover {
  color: #F55B23;
}
#trackList .rightContainer .commentList-box {
  position: relative;
}
#trackList .rightContainer .commentList-box::after {
  bottom: 0;
  background-image: linear-gradient(180deg, #fff 0%, #f2f2f2 100%);
}
#trackList .rightContainer .commentList-box::before {
  top: 0;
  background-image: linear-gradient(0deg, #fff 0%, #f2f2f2 100%);
}
#trackList .rightContainer .commentList-box::after,
#trackList .rightContainer .commentList-box::before {
  position: absolute;
  content: '';
  left: 0;
  width: 100%;
  height: 6px;
  z-index: 0;
}
#trackList .rightContainer .commentList .commentListCon {
  max-height: 600px;
  overflow-y: scroll;
  margin-right: 0;
}
#trackList .rightContainer .commentList .commentListCon::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}
#trackList .rightContainer .commentList .commentListCon::-webkit-scrollbar {
  width: 2px;
  background-color: #fff;
}
#trackList .rightContainer .commentList .commentListCon::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(158, 158, 158, 0.6);
}
#trackList .rightContainer .commentList .commentListCon::-webkit-scrollbar-thumb:hover {
  background-color: rgba(158, 158, 158, 0.9);
}
#trackList .rightContainer .commentList .commentListCon .commentItem {
  width: 100%;
}
#trackList .rightContainer .commentList .commentListCon .commentItem .commentItem {
  width: 100%;
}
